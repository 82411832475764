'use client';

import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import type { CourseStoreContextState } from './courseStore';
import { defaultCourseStoreState } from './courseStore';
import { continueCourseAccess, getCourseAccess } from './actions';
import { useUserStore } from '../user/UserStoreContext';

const StoreContext = createContext(defaultCourseStoreState);

export const useInteractiveCourseStore = <T = CourseStoreContextState,>(
    selector: (state: CourseStoreContextState) => T = (state) => state as unknown as T,
): T => {
    const state = useContext(StoreContext);

    return selector(state);
};

export default function CourseStoreContext({
    children,
    courseId,
    lectureId,
    courseAccess = defaultCourseStoreState.courseUserAccess.data,
}: {
    children: ReactNode;
    courseId: number;
    lectureId?: number;
    courseAccess?: any;
}) {
    const { user } = useUserStore();
    const [previewModalData, setPreviewModalState] = useState(
        defaultCourseStoreState.previewModal.data,
    );

    const [courseAccessData, setCourseAccessState] = useState({
        ...courseAccess,
        loading: !courseAccess.id && user.id,
    });

    useEffect(() => {
        const callback = async () => {
            let courseAccess
            if (lectureId) {
                courseAccess = await continueCourseAccess(courseId, lectureId)
            } else {
                courseAccess = await getCourseAccess(courseId)
            }

            setCourseAccessState({ ...(courseAccess || {}), loading: false })
        }

        if (!courseAccessData.id && user.id) {
            callback()
        }
    }, [user.id, courseAccess.id])

    // const initialShow = !isMobile() && activeLecture.type === "VIDEO"
    const [showSyllabusFlag, setShowSyllabus] = useState(false);

    return (
        <StoreContext.Provider
            value={{
                previewModal: {
                    data: previewModalData,
                    // @ts-ignore
                    setState: setPreviewModalState,
                },
                courseUserAccess: {
                    data: courseAccessData,
                    setState: setCourseAccessState,
                },
                showSyllabus: {
                    showSyllabus: showSyllabusFlag,
                    setShowSyllabus,
                },
            }}
        >
            {children}
        </StoreContext.Provider>
    );
}
