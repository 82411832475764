import { getLocalStorageItem } from '@repo/utils/localStorageUtils';
import { toKebabCase } from '@repo/utils/stringUtils';

export const getCourseActiveIndexes = (course = {}, activeLectureId) => {
    const indexes = {};
    let lectureCount = 0;
    let interationsBeforeLectureId = 0;

    if (!course.sectionGroups) {
        return indexes;
    }

    for (
        let sectionGroupIndex = 0;
        sectionGroupIndex < course.sectionGroups?.length;
        sectionGroupIndex++
    ) {
        const sectionGroup = course.sectionGroups[sectionGroupIndex];
        for (let sectionIndex = 0; sectionIndex < sectionGroup.sections?.length; sectionIndex++) {
            const section = sectionGroup.sections[sectionIndex];
            for (let lectureIndex = 0; lectureIndex < section.lectures?.length; lectureIndex++) {
                const lecture = section.lectures[lectureIndex];
                lectureCount++;

                if (lecture.type === 'INTERACTION') {
                    interationsBeforeLectureId++;
                }

                if (lecture.id === activeLectureId) {
                    indexes.selectedSectionGroupIndex = sectionGroupIndex;
                    indexes.selectedSectionIndex = sectionIndex;
                    indexes.selectedLectureIndex = lectureIndex;
                    indexes.selectedLectureOrderNumber = lectureCount - interationsBeforeLectureId;
                    indexes.interationsBeforeLectureId = interationsBeforeLectureId;

                    return indexes;
                }
            }
        }
    }

    return indexes;
};
export const getLastFinishedLectureIndexes = (course, courseUserAccess) => {
    const allSectionGroups = course?.sectionGroups || [];
    const allSections = (sectionGroupIndex) => allSectionGroups[sectionGroupIndex]?.sections || [];
    const allLectures = (sectionGroupIndex, sectionIndex) =>
        allSections(sectionGroupIndex)[sectionIndex]?.lectures || [];

    const indexes = {
        selectedLectureIndex: -1,
        selectedSectionIndex: 0,
        selectedSectionGroupIndex: 0,
    };

    for (let i = 0; i < allSectionGroups.length; i++) {
        const sections = allSections(i);
        for (let j = 0; j < sections.length; j++) {
            const lectures = allLectures(i, j);
            for (let k = 0; k < lectures.length; k++) {
                const lecture = lectures[k];

                const progress = getLectureProgress(courseUserAccess, lecture.id);
                if (progress?.finished) {
                    indexes.selectedLectureIndex = k;
                    indexes.selectedSectionIndex = j;
                    indexes.selectedSectionGroupIndex = i;
                }
            }
        }
    }

    return indexes;
};

export const getLectureNavigation = (course, activeLectureId, navigate) => {
    const { selectedSectionGroupIndex, selectedSectionIndex, selectedLectureIndex } =
        getCourseActiveIndexes(course, activeLectureId);

    const indexes = {
        selectedLectureIndex,
        selectedSectionIndex,
        selectedSectionGroupIndex,
    };

    const allSectionGroups = course?.sectionGroups || [];
    const allSections = (sectionGroupIndex) => allSectionGroups[sectionGroupIndex]?.sections || [];
    const allLectures = (sectionGroupIndex, sectionIndex) =>
        allSections(sectionGroupIndex)[sectionIndex]?.lectures || [];
    const getters = {
        allSectionGroups,
        allSections,
        allLectures,
    };

    const isOk = navigate(indexes, getters);

    if (isOk) {
        return {
            ok: true,
            lecture:
                course?.sectionGroups[indexes.selectedSectionGroupIndex]?.sections[
                    indexes.selectedSectionIndex
                ]?.lectures[indexes.selectedLectureIndex] || {},
            indexes,
        };
    }

    return {
        ok: false,
        lecture: {},
        indexes,
    };
};

export const getPreviousLecture = (course, activeLectureId) => {
    const indexParser = (indexes, { allLectures, allSections }) => {
        if (indexes.selectedLectureIndex > 0) {
            indexes.selectedLectureIndex--;
        } else if (indexes.selectedSectionIndex > 0) {
            indexes.selectedSectionIndex--;
            indexes.selectedLectureIndex =
                allLectures(indexes.selectedSectionGroupIndex, indexes.selectedSectionIndex)
                    .length - 1;
        } else if (indexes.selectedSectionGroupIndex > 0) {
            indexes.selectedSectionGroupIndex--;
            indexes.selectedSectionIndex =
                allSections(indexes.selectedSectionGroupIndex).length - 1;
            indexes.selectedLectureIndex =
                allLectures(indexes.selectedSectionGroupIndex, indexes.selectedSectionIndex)
                    .length - 1;
        } else {
            return false;
        }

        return true;
    };

    return getLectureNavigation(course, activeLectureId, indexParser);
};

export const getNextLecture = (course, activeLectureId) => {
    const indexParser = (indexes, { allLectures, allSections, allSectionGroups }) => {
        if (
            indexes.selectedLectureIndex <
            allLectures(indexes.selectedSectionGroupIndex, indexes.selectedSectionIndex).length - 1
        ) {
            indexes.selectedLectureIndex++;
        } else if (
            indexes.selectedSectionIndex <
            allSections(indexes.selectedSectionGroupIndex).length - 1
        ) {
            indexes.selectedSectionIndex++;
            indexes.selectedLectureIndex = 0;
        } else if (indexes.selectedSectionGroupIndex < allSectionGroups.length - 1) {
            indexes.selectedSectionGroupIndex++;
            indexes.selectedSectionIndex = 0;
            indexes.selectedLectureIndex = 0;
        } else {
            return false;
        }

        return true;
    };

    return getLectureNavigation(course, activeLectureId, indexParser);
};

export const getActiveLecture = (course, activeLectureId) => {
    const { selectedSectionGroupIndex, selectedSectionIndex, selectedLectureIndex } =
        getCourseActiveIndexes(course, activeLectureId);

    const lecture =
        course?.sectionGroups?.[selectedSectionGroupIndex]?.sections[selectedSectionIndex]
            ?.lectures[selectedLectureIndex] || {};

    return lecture;
};

export const getVideoLectureNumber = (course, lectureId) => {
    const { selectedLectureOrderNumber } = getCourseActiveIndexes(course, lectureId);

    return selectedLectureOrderNumber;
};

export const getInteractionLectureNumber = (course, lectureId) => {
    const { selectedLectureOrderNumber } = getCourseActiveIndexes(course, lectureId);

    return selectedLectureOrderNumber;
};

export const getLectureProgress = (courseUserAccess, lectureId) => {
    if (!courseUserAccess) {
        return {};
    }

    const courseLectureProgress = courseUserAccess.courseLectureProgresses?.find(
        (courseLectureProgress) => courseLectureProgress.lectureId === lectureId,
    );

    return courseLectureProgress || {};
};

export const getFinishedLectureProgress = (course, field) => {
    const items =
        (course.courseUserAccess?.courseLectureProgresses || []).filter((courseLectureProgress) =>
            Boolean(courseLectureProgress.finished),
        ) || [];

    if (field) {
        return items.map((item) => item[field]);
    }

    return items;
};

export const getFinishedLecturesCount = (course, lectures) => {
    if (course) {
        const allProgresses = getFinishedLectureProgress(course, 'lectureId');

        return lectures.filter((lecture) => allProgresses.includes(lecture.id)).length;
    }

    return 0;
};

export const getLectureTitle = (id, title, type, _noId = false, prefix = '') => {
    const minPrefix = '';

    return `${prefix}${minPrefix} ${title}`;
};

export const getCourseProgress = (course, courseUserAccess, simple = false) => {
    if (!courseUserAccess) {
        return { lecturesFinished: 0, totalLecturesCount: 0, progress: 0 };
    }

    const lectureProgressSelector = simple
        ? courseUserAccess.courseLectureProgresses
        : courseUserAccess.courseLectureProgresses;

    const lecturesFinished =
        lectureProgressSelector?.filter((progress) => Boolean(progress.finished)).length || 0;
    const totalLecturesCount = course.statistics?.lectureCount || lecturesFinished;
    const progress = (lecturesFinished / totalLecturesCount) * 100 || 0;

    return {
        lecturesFinished,
        totalLecturesCount,
        progress,
    };
};

export const isLectureLocked = (
    userTypeId,
    lastFinishedLectureIndexes,
    allSectionGroups = [],
    sectionIndex,
    sectionGroupIndex,
    lectureIndex,
) => {
    const isNotFirstSectionGroup = sectionGroupIndex > 0;
    const allPreviousSectionGroupSections = isNotFirstSectionGroup
        ? allSectionGroups[sectionGroupIndex - 1]?.sections || []
        : [];
    const lastSection = allPreviousSectionGroupSections[allPreviousSectionGroupSections.length - 1];
    const lastSectionLecture = lastSection?.lectures || [];

    const isSectionGroupGreaterThenLastFinishedSectionGroup =
        sectionGroupIndex > lastFinishedLectureIndexes.selectedSectionGroupIndex;

    const isSectionGreaterThenLastFinishedSection =
        sectionIndex > lastFinishedLectureIndexes.selectedSectionIndex;

    const currentSectionGroupIsNextFromLastFinished =
        lastFinishedLectureIndexes.selectedSectionGroupIndex + 1 === sectionGroupIndex;

    const isTheLastSectionInGroup =
        allPreviousSectionGroupSections.length - 1 ===
        lastFinishedLectureIndexes.selectedSectionIndex;

    const isTheLastLectureInSection =
        lastFinishedLectureIndexes.selectedLectureIndex === lastSectionLecture.length - 1;

    const previousLectureIndex =
        sectionIndex !== 0
            ? allSectionGroups[sectionGroupIndex]?.sections[sectionIndex - 1]?.lectures?.length - 1
            : sectionGroupIndex !== 0
              ? lastSectionLecture.length - 1
              : -1;

    const isSectionGroupUnlocked = isSectionGroupGreaterThenLastFinishedSectionGroup
        ? isNotFirstSectionGroup &&
          currentSectionGroupIsNextFromLastFinished &&
          isTheLastSectionInGroup &&
          isTheLastLectureInSection
        : true;

    const isFirstSectionInGroup = sectionIndex === 0;

    const isNextToFinishedSection =
        lastFinishedLectureIndexes.selectedSectionIndex + 1 === sectionIndex;

    const isSectionUnlocked =
        isSectionGroupGreaterThenLastFinishedSectionGroup && isSectionGreaterThenLastFinishedSection
            ? !isFirstSectionInGroup && isNextToFinishedSection
                ? lastFinishedLectureIndexes.selectedLectureIndex === previousLectureIndex
                : isFirstSectionInGroup &&
                  isNotFirstSectionGroup &&
                  currentSectionGroupIsNextFromLastFinished &&
                  isTheLastSectionInGroup &&
                  isTheLastLectureInSection
            : true;

    const isCurrentSectionGroupAndSection =
        sectionGroupIndex === lastFinishedLectureIndexes.selectedSectionGroupIndex &&
        sectionIndex === lastFinishedLectureIndexes.selectedSectionIndex;

    const isLectureAfterFinishedLecture =
        lectureIndex <= lastFinishedLectureIndexes.selectedLectureIndex + 1;

    const isLectureUnlocked =
        sectionGroupIndex > lastFinishedLectureIndexes.selectedSectionGroupIndex ||
        (sectionGroupIndex === lastFinishedLectureIndexes.selectedSectionGroupIndex &&
            sectionIndex >= lastFinishedLectureIndexes.selectedSectionIndex)
            ? isCurrentSectionGroupAndSection
                ? isLectureAfterFinishedLecture
                : !isFirstSectionInGroup && isNextToFinishedSection
                  ? lastFinishedLectureIndexes.selectedLectureIndex === previousLectureIndex &&
                    lectureIndex === 0
                  : isFirstSectionInGroup &&
                    isNotFirstSectionGroup &&
                    currentSectionGroupIsNextFromLastFinished &&
                    isTheLastSectionInGroup &&
                    isTheLastLectureInSection &&
                    lectureIndex === 0
            : true;

    const locked = !isSectionGroupUnlocked || !isSectionUnlocked || !isLectureUnlocked;

    // enable admins to see all
    return userTypeId !== 3 && locked;
};

export const getLectureType = (lecture) => {
    if (lecture.type === 'ARTICLE' || lecture.articleId) {
        return 'ARTICLE';
    }

    if (lecture.type === 'QUIZ' || lecture.quizId) {
        return 'QUIZ';
    }

    if (lecture.type === 'INTERACTION' && lecture.interactionGroup?.sourceType === 'ILLUSTRATION') {
        return 'ILLUSTRATION';
    }

    if (lecture.type === 'INTERACTION' && lecture.interactionGroup?.sourceType === 'CODE') {
        return 'CODE';
    }

    return lecture.interactionGroup?.sourceType || lecture.type;
};

export const generateLectureUrl = (course, lectureTitle) => {
    return `/courses/${course.urlSlug}/lessons/${toKebabCase(lectureTitle)}`;
};

export const getActiveInstructionIndex = (allInteractions, lastFinishedInteraction) => {
    const lastFinishedIndex = allInteractions.findIndex(
        (interaction) => interaction.id === lastFinishedInteraction?.lastFinishedInteractionId,
    );

    if (lastFinishedIndex + 1 < allInteractions.length) {
        return lastFinishedIndex + 1;
    }

    return lastFinishedIndex;
};
