export interface CoursePreviewModalProps {
    open: boolean;
    activeVideoIndex: number;
}

export interface CourseUserAccessProps {
    loading: boolean;
    lastUnlockedLectureId: number | null;
    lastVisitedLectureId: number;
    id?: number;
    courseId?: number;
    affiliate?: string;
    userId?: number;
    watchTime?: number;
    lastFinishedInteractionId?: number | null;
    createdAt?: string;
    updatedAt?: string;
    courseLectureProgresses: {
        id?: number;
        courseUserAccessId?: number;
        lectureId?: number;
        lastFinishedInteractionId?: number | null;
        finished?: boolean;
        playedSeconds?: number;
        xpGained?: number;
        createdAt?: string;
        updatedAt?: string;
    }[];
}

export interface courseUserAccessStoreType {
    data: CourseUserAccessProps;

    setState: (data?: CourseUserAccessProps) => void;
}

export interface CourseStoreContextState {
    previewModal: {
        data: CoursePreviewModalProps;

        setState: (data?: CoursePreviewModalProps) => void;
    };
    courseUserAccess: courseUserAccessStoreType;
    myAvailableCourses: [];
    showSyllabus: {
        showSyllabus: boolean;

        setShowSyllabus: (show: boolean) => void;
    };
}

export const defaultCourseStoreState: CourseStoreContextState = {
    previewModal: {
        data: {
            open: false,
            activeVideoIndex: 0,
        },
        setState: () => {},
    },
    courseUserAccess: {
        data: {
            loading: true,
            lastUnlockedLectureId: null,
            lastVisitedLectureId: 0,
            courseLectureProgresses: [],
        },
        setState: () => {},
    },
    myAvailableCourses: [],
    showSyllabus: {
        showSyllabus: false,
        setShowSyllabus: () => {},
    },
};
