import axios from 'axios';
import queryString from 'query-string';
import fetchWrapper from '@repo/fetcher';

// public

const getAllCourses = (promoCode, languageSlug, subjectSlug, limit) => {
    const query = `?${queryString.stringify({ promoCode, languageSlug, subjectSlug, limit })}`;

    return fetchWrapper.get(`/courses${query}`);
};

const getAllArticles = (languageSlug, subjectSlug, type) => {
    const query = `?${queryString.stringify({ languageSlug, subjectSlug, type })}`;

    return fetchWrapper.get(`/articles${query}`);
};

const getCourseBySlug = (urlSlug, affiliate, config) => {
    const query = affiliate ? `?affiliate=${affiliate}` : '';

    return fetchWrapper.get(`/courses/slug/${urlSlug}${query}`, config);
};

const getCourse = (courseId) => {
    return fetchWrapper.get(`/courses/${courseId}`);
};

const generateCourseReview = (data) => {
    return axios.post('https://us-central1-aiseo-official.cloudfunctions.net/apiFree', data);
};

const getCourseLearningHighlights = (courseId) => {
    return fetchWrapper.get(`/courses/${courseId}/learning_highlights`);
};

const getCourseStatistics = (courseId) => {
    return fetchWrapper.get(`/courses/${courseId}/statistics`);
};

const getCourseContent = (courseId) => {
    return fetchWrapper.get(`/courses/${courseId}/content`);
};

const getCourseReviews = (courseId) => {
    return fetchWrapper.get(`/courses/${courseId}/reviews`);
};

const getPreviewVideos = (courseId) => {
    return fetchWrapper.get(`/courses/${courseId}/preview_videos`);
};

//  auth
const getMyCourses = (userId, headers) => {
    return fetchWrapper.get(`/users/${userId}/courses/available`, {
        headers,
    });
};

const buyCourse = (userId, courseId, promoCode, orderId, affiliate) => {
    return fetchWrapper.post(`/users/${userId}/courses/${courseId}/buy`, {
        orderId,
        promoCode,
        affiliate,
    });
};

const getLecture = (courseId, lectureId) => {
    return fetchWrapper.get(`/courses/${courseId}/lectures/${lectureId}`);
};

const setLastVisitLecture = (courseId, lectureId, headers) => {
    return fetchWrapper.get(`/courses/${courseId}/lectures/${lectureId}/view`, {
        headers,
        cache: 'no-store',
    });
};

const progressLecture = (userId, courseId, lectureId, data) => {
    return fetchWrapper.post(
        `/users/${userId}/courses/${courseId}/lectures/${lectureId}/progress`,
        data,
    );
};

const addCourseRating = (userId, courseId, data) => {
    return fetchWrapper.post(`/users/${userId}/courses/${courseId}/review`, data);
};

const getMyCourseReview = (userId, courseId, headers) => {
    return fetchWrapper.get(`/users/${userId}/courses/${courseId}/reviews/my`, {
        headers,
        cache: 'no-store',
    });
};

const getMyCourseAccess = (courseId, headers) => {
    return fetchWrapper.get(`/courses/${courseId}/access`, {
        headers,
        cache: 'no-store',
    });
};

const getMyCourseProgress = (userId, courseId, headers) => {
    return fetchWrapper.get(`/users/${userId}/courses/${courseId}/progress`, {
        headers,
        cache: 'no-store',
    });
};
const lectureResources = (slug) => {
    return fetchWrapper.get(`/courses/slug/${slug}/resources`);
};

const getLanguages = () => {
    return fetchWrapper.get(`/languages`);
};

const getSubjects = () => {
    return fetchWrapper.get(`/subjects`);
};

const getArticle = (articleSlug) => {
    return fetchWrapper.get(`/articles/${articleSlug}`);
};

const runWorkspaceCode = (data) => {
    return fetchWrapper.post('/workspace/run', data);
};

const runFileCode = (data) => {
    return fetchWrapper.post('/code/run', data);
};

const courseService = {
    // public
    getAllCourses,
    getCourseBySlug,
    getCourse,
    getCourseLearningHighlights,
    getCourseStatistics,
    getCourseContent,
    getCourseReviews,
    getPreviewVideos,
    lectureResources,
    getLecture,

    // auth
    getMyCourses,
    buyCourse,
    setLastVisitLecture,
    progressLecture,
    generateCourseReview,
    addCourseRating,
    getMyCourseReview,
    getMyCourseAccess,
    getMyCourseProgress,
    runWorkspaceCode,
    runFileCode,
};

export default courseService;
