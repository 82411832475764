import courseService from '@/service/course.service';
import type { courseUserAccessStoreType } from './courseStore';
import { defaultCourseStoreState } from './courseStore';

export const getCourseAccess = async (courseId: number | null, headers?: any) => {
    if (courseId) {
        try {
            const {
                data: {
                    result: { courseAccess },
                },
            } = await courseService.getMyCourseAccess(courseId, headers);

            // if (userId && courseAccess.id) {
            //     const { data: { result: myCourseReview } } = await courseService.getMyCourseReview(courseId, headers)
            // }

            return courseAccess;
        } catch (error) {
            // .
        }
    }

    return { courseAccess: defaultCourseStoreState.courseUserAccess.data };
};

export const continueCourseAccess = async (
    courseId: number | null,
    lectureId: number,
    headers?: any,
) => {
    if (courseId) {
        try {
            const {
                data: {
                    result: { courseUserAccess },
                },
            } = await courseService.setLastVisitLecture(courseId, lectureId, headers);

            // if (userId && courseAccess.id) {
            //     const { data: { result: myCourseReview } } = await courseService.getMyCourseReview(courseId, headers)
            // }

            return courseUserAccess;
        } catch (error) {
            // .
        }
    }

    return { courseAccess: defaultCourseStoreState.courseUserAccess.data };
};

export const progressLecture = async (
    userId: number,
    courseId: number,
    courseUserAccess: courseUserAccessStoreType,
    lastVisitedLectureId: number,
    data: any = {},
) => {
    const {
        data: { result },
    } = await courseService.progressLecture(userId, courseId, lastVisitedLectureId, data);

    const courseLectureProgressesReduxData = courseUserAccess.data.courseLectureProgresses || [];

    const progressExists = courseLectureProgressesReduxData.find(
        (courseUserAccessTemp: any) => courseUserAccessTemp.lectureId === result.lectureId,
    );

    if (!progressExists) {
        courseLectureProgressesReduxData.push({ lectureId: result.lectureId });
    }

    const courseLectureProgresses = courseLectureProgressesReduxData.map(
        (courseUserAccessTemp: any) => {
            if (courseUserAccessTemp.lectureId === result.lectureId) {
                return result;
            }

            return courseUserAccessTemp;
        },
    );

    // @ts-expect-error
    courseUserAccess.setState({ courseLectureProgresses });
    courseUserAccess.data.courseLectureProgresses = courseLectureProgresses;

    return courseUserAccess;
};
